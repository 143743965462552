import Config from './config'

const api = Config.api

const vehicleTypeAPIRoot = api.BASE_URL + '/vehicleType'

class VehicleTypeService {
  createVehicleType (VehicleTypeModel) {
    return api.postCall(vehicleTypeAPIRoot, VehicleTypeModel, api.ShowAlert, api.ShowAlert)
  }

  getVehicleTypes () {
    return api.getCall(vehicleTypeAPIRoot, api.DoNothing, api.ShowAlert)
  }

  getVehicleTypesWithCategoriesAndSubCategories () {
    return api.getCall(vehicleTypeAPIRoot + '/with-category-and-sub-category', api.DoNothing, api.ShowAlert)
  }

  updateVehicleType (VehicleTypeModel) {
    return api.putCall(vehicleTypeAPIRoot + '/' + VehicleTypeModel.id, VehicleTypeModel, api.ShowAlert, api.ShowAlert)
  }

  deleteVehicleType (typeId) {
    return api.deleteCall(vehicleTypeAPIRoot + '/' + typeId, api.ShowAlert, api.ShowAlert)
  }
}
export default new VehicleTypeService()
