import UtilityService from '../services/utility.service'
import _ from 'lodash'
import VehicleSubCategoryModel from '@/models/VehicleSubCategoryModel'
export default class VehicleCategoryNewModel {
  constructor () {
    this.id = 0
    this.title = ''
    this.title_bangla = ''
    this.type_id = 0
    this.type_name = ''
    this.sub_categories = []
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.title = data.title
    this.title_bangla = data.title_bangla
    this.type_id = data.type_id
    this.type_name = data.type_name
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    this.sub_categories = _.map(data.sub_categories, function (sc) {
      return new VehicleSubCategoryModel().load(sc)
    })
    return this
  }
}
