import Config from './config'
var api = Config.api

const brandAPIRoot = api.BASE_URL + '/brand'

class BrandService {
  createBrand (BrandModel) {
    return api.postCall(brandAPIRoot, BrandModel, api.ShowAlert, api.ShowAlert)
  }

  getBrands () {
    return api.getCall(brandAPIRoot, api.DoNothing, api.ShowAlert)
  }

  getBrandsWithModels () {
    return api.getCall(brandAPIRoot + '/aggregate/with-models', api.DoNothing, api.ShowAlert)
  }

  getBrandById (BrandId) {
    return api.getCall(brandAPIRoot + '/' + BrandId, api.DoNothing, api.ShowAlert)
  }

  updateBrand (BrandModel) {
    return api.putCall(brandAPIRoot + '/' + BrandModel.id, BrandModel, api.ShowAlert, api.ShowAlert)
  }

  deleteBrand (BrandId) {
    return api.deleteCall(brandAPIRoot + '/' + BrandId, api.ShowAlert, api.ShowAlert)
  }

  getModelByBrandId (BrandId) {
    return api.getCall(brandAPIRoot + '/' + BrandId + '/model', api.DoNothing, api.ShowAlert)
  }
}
export default new BrandService()
