import UtilityService from '../services/utility.service'
import _ from 'lodash'
import VehicleCategoryNewModel from '@/models/VehicleCategoryNewModel'
export default class VehicleTypeModel {
  constructor (typeId) {
    this.id = 0
    this.title = ''
    this.title_bangla = ''
    this.categories = []
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.title = data.title
    this.title_bangla = data.title_bangla
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    this.categories = _.map(data.categories, function (c) {
      return new VehicleCategoryNewModel().load(c)
    })
    return this
  }
}
