<template>
  <div class="page-heading">
    <div class="page-title">
      <div class="row">
        <div class="col-12 col-md-6 order-md-1 order-last">
          <h3>All Products</h3>
          <p class="text-subtitle text-muted">View your products and add new ones</p>
        </div>
        <div class="col-12 col-md-6 order-md-2 order-first">
          <nav aria-label="breadcrumb" class="breadcrumb-header float-start float-lg-end">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="{ name: 'home' }" >Dashboard</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">All Products</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <!-- Basic Tables start -->
    <section class="section">
      <div class="card">
        <div class="card-header">
          Bulk upload from excel
        </div>
        <div class="card-body">
          <ExcelTool @ExcelProductsUpload="OnExcelProductsUpload" :products="productBlueprints"></ExcelTool>
        </div>
      </div>

    </section>
    <!-- Basic Tables end -->

    <!-- // Basic multiple Column Form section start -->
    <section id="multiple-column-form">
      <div class="row match-height">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-7">
                  <button @click="openAddForm=!openAddForm" class="btn btn-outline-dark card-title">Add Product</button>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <Field type="text" class="form-control" v-model="searchText" name="search" placeholder="Search by name" />
                    <ErrorMessage class="text-danger" name="search" />
                  </div>
                </div>
              </div>
            </div>
            <div v-show="openAddForm" class="card-content">
              <div class="card-body">
                <Form class="form" v-slot="{ handleSubmit }" @submit.prevent="createOrUpdateProduct" :validation-schema="ValidationSchema">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Title</label>
                        <Field type="text" class="form-control" v-model="ProductModel.name" name="name" placeholder="Product name" />
                        <ErrorMessage class="text-danger" name="name" />
                      </div>
                    </div>

                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Title (In Bangla)</label>
                        <Field type="text" class="form-control" v-model="ProductModel.name_bn"  name="name_bn" placeholder="Product name in bangla" />
                        <ErrorMessage class="text-danger" name="name_bn" />
                      </div>
                    </div>

                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="thumbnails">Thumbnail Images</label>
                        <Field @change="onThumbnailUpload" type="file" class="form-control" name="thumbnails[]" placeholder="Thumbnail Images" />
                        <ErrorMessage class="text-danger" name="thumbnails" />
                        <div class="row mt-2">
                          <template v-for="(img, index) in ProductModel.thumbnails.split('##**')" :key="index">
                            <div class="hover-cross col-md-2 mr-2 img-thumbnail" v-if="img" @click="removeThumbnailImage(img)">
                              <img :src="img" class="col-md-12" alt="">
                              <div class="close-icon">
                                <i class="fa fa-times"></i>
                              </div>
                            </div>
                          </template>
                          <div class="hover-cross col-md-2 mr-2 img-thumbnail" v-if="thumbnails.length" @click="removeThumbnailImage('uploads')">
                            <img :src="'data:image/png;base64,' + thumbnails[0].url" class="col-md-12" alt="">
                            <div class="close-icon">
                              <i class="fa fa-times"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="feature_photos">Feature Images</label>
                        <Field @change="onFeaturePhotosUpload" type="file" multiple class="form-control" name="feature_photos[]" placeholder="Feature images" />
                        <ErrorMessage class="text-danger" name="feature_photos" />
                        <div class="row mt-2">
                          <template v-for="(img, index) in ProductModel.feature_photos.split('##**')" :key="index">
                            <div class="hover-cross col-md-2 mr-2 img-thumbnail" v-if="img" @click="removeFeaturePhotos(img)">
                              <img :src="img" class="col-md-12" alt="">
                              <div class="close-icon">
                                <i class="fa fa-times"></i>
                              </div>
                            </div>
                          </template>
                          <div class="hover-cross col-md-2 mr-2 img-thumbnail" v-if="feature_photos.length" @click="removeFeaturePhotos('uploads')">
                            <img :src="'data:image/png;base64,' + feature_photos[0].url" class="col-md-12" alt="">
                            <div class="close-icon">
                              <i class="fa fa-times"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Feature</label>
                        <Field type="text" class="form-control" v-model="ProductModel.feature" name="feature" placeholder="Product feature in bangla" />
                        <ErrorMessage class="text-danger" name="feature" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Feature (In Bangla)</label>
                        <Field type="text" class="form-control" v-model="ProductModel.feature_bn" name="feature_bn" placeholder="Product feature in bangla" />
                        <ErrorMessage class="text-danger" name="feature_bn" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Overview</label>
                        <Field type="text" class="form-control" v-model="ProductModel.overview" name="overview" placeholder="Product overview in bangla" />
                        <ErrorMessage class="text-danger" name="overview" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Overview (In Bangla)</label>
                        <Field type="text" class="form-control" v-model="ProductModel.overview_bn" name="overview_bn" placeholder="Product overview in bangla" />
                        <ErrorMessage class="text-danger" name="overview_bn" />
                      </div>
                    </div>
                    <div class="form-group col-12">
                      <div class='form-check'>
                        <div class="checkbox">
                          <input type="checkbox" id="checkbox5" class='form-check-input'
                                 checked>
                          <label for="checkbox5">Active</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" v-for="rootProperty in ProductModel.properties" :key="rootProperty.id">

                    <div class="divider divider-left">
                      <div class="divider-text"> <b>{{rootProperty.title}}</b> </div>
                    </div>

                    <div class="row" v-for="property in rootProperty.childs" :key="property.id">
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <label for="first-name-column">{{property.title}}</label>
                          <template v-if="property.title === 'Brand Name'">
                            <Field as="select" :class="'form-control'" v-model="property.specification.value" :name="property.title" :placeholder="property.title" @change="autoSetBanglaPropertyValue(property)">
                              <option disabled selected value="">Select Brand</option>
                              <option v-for="brand in brands" :key="brand.id" :value="brand.title">{{ brand.title }}</option>
                            </Field>
                          </template>
                          <template v-else-if="property.title === 'Vehicle Category'">
                            <Field as="select" :class="'form-control'" v-model="property.specification.value" :name="property.title" :placeholder="property.title" @change="autoSetBanglaPropertyValue(property)">
                              <option disabled selected value="">Select Category</option>
                              <option v-for="category in categories" :key="category.id" :value="category.title">{{ category.title }}</option>
                            </Field>
                          </template>
                          <template v-else-if="property.title === 'Vehicle Sub-Category'">
                            <Field as="select" :class="'form-control'" v-model="property.specification.value" :name="property.title" :placeholder="property.title" @change="autoSetBanglaPropertyValue(property)">
                              <option disabled selected value="">Select Sub Category</option>
                              <option v-for="subCategory in subCategories" :key="subCategory.id" :value="subCategory.title">{{ subCategory.title }}</option>
                            </Field>
                          </template>
                          <template v-else-if="property.title === 'Country of Origin'">
                            <Field as="select" :class="'form-control'" v-model="property.specification.value" :name="property.title" :placeholder="property.title" @change="autoSetBanglaPropertyValue(property)">
                              <option disabled selected value="">Select Country of Origin</option>
                              <option v-for="country in countries" :key="country.id" :value="country.title">{{ country.title }}</option>
                            </Field>
                          </template>
                          <template v-else-if="property.title === 'Vehicle Type'">
                            <Field as="select" :class="'form-control'" v-model="property.specification.value" :name="property.title" :placeholder="property.title" @change="autoSetBanglaPropertyValue(property)">
                              <option disabled selected value="">Select Vehicle Type</option>
                              <option v-for="type in vehicleTypes" :key="type.id" :value="type.title">{{ type.title }}</option>
                            </Field>
                          </template>
                          <template v-else>
                            <Field type="text" class="form-control" v-model="property.specification.value" :name="property.title" :placeholder="property.title" />
                          </template>
                          <ErrorMessage class="text-danger" name="name" />
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <label for="first-name-column">{{property.title_bn}}</label>
                          <template v-if="property.title === 'Brand Name'">
                            <Field as="select" :class="'form-control'" disabled="" v-model="property.specification.value_bn" :name="property.title_bn" :placeholder="property.title_bn">
                              <option disabled selected value="">Select Brand</option>
                              <option v-for="brand in brands" :key="brand.id" :value="brand.title_bangla">{{ brand.title_bangla }}</option>
                            </Field>
                          </template>
                          <template v-else-if="property.title === 'Vehicle Category'">
                            <Field as="select" :class="'form-control'" disabled="" v-model="property.specification.value_bn" :name="property.title_bn" :placeholder="property.title_bn">
                              <option disabled selected value="">Select Category</option>
                              <option v-for="category in categories" :key="category.id" :value="category.title_bangla">{{ category.title_bangla }}</option>
                            </Field>
                          </template>
                          <template v-else-if="property.title === 'Vehicle Sub-Category'">
                            <Field as="select" :class="'form-control'" disabled="" v-model="property.specification.value_bn" :name="property.title_bn" :placeholder="property.title_bn">
                              <option disabled selected value="">Select Sub Category</option>
                              <option v-for="subCategory in subCategories" :key="subCategory.id" :value="subCategory.title_bangla">{{ subCategory.title_bangla }}</option>
                            </Field>
                          </template>
                          <template v-else-if="property.title === 'Country of Origin'">
                            <Field as="select" :class="'form-control'" disabled="" v-model="property.specification.value_bn" :name="property.title_bn" :placeholder="property.title_bn">
                              <option disabled selected value="">Select Brand</option>
                              <option v-for="country in countries" :key="country.id" :value="country.title_bangla">{{ country.title_bangla }}</option>
                            </Field>
                          </template>
                          <template v-else-if="property.title === 'Vehicle Type'">
                            <Field as="select" :class="'form-control'" disabled="" v-model="property.specification.value_bn" :name="property.title_bn" :placeholder="property.title_bn">
                              <option disabled selected value="">Select Vehicle Type</option>
                              <option v-for="type in vehicleTypes" :key="type.id" :value="type.title_bangla">{{ type.title_bangla }}</option>
                            </Field>
                          </template>
                          <template v-else>
                            <Field type="text" class="form-control" v-model="property.specification.value_bn" :name="property.title_bn" :placeholder="property.title_bn" />
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 d-flex justify-content-end">
                      <button class="btn btn-primary me-1 mb-1" v-show="!ProductModel.id" @click="handleSubmit($event, createOrUpdateProduct)">Create</button>
                      <button class="btn btn-primary me-1 mb-1" v-show="ProductModel.id" @click="handleSubmit($event, updateProduct)">Update</button>
                      <button class="btn btn-light-secondary me-1 mb-1" @click="reset">Reset</button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->

    <!-- Basic Tables start -->
    <section class="section">
      <div class="card">
        <div class="card-header">
          <button class="badge bg-success" @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class="card-body">
          <table class="table table-striped" id="table1">
            <thead>
            <tr>
              <th width="5%">#</th>
              <th width="30%">Name</th>
              <th width="35%">Name_BN</th>
              <th width="10%">Updated At</th>
              <th width="5%">ID</th>
              <th width="5%">Status</th>
              <th width="10%">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(product, index) in displayProducts" :key="index" >
              <td>{{ index+1 }}</td>
              <td>{{ product.name }}</td>
              <td>{{ product.name_bn }}</td>
              <td>{{ product.updated_at }}</td>
              <td>{{ product.id }}</td>
              <td>
                <span class="badge bg-success">
                  <i class="fa fa-check"></i>
                </span>
              </td>
              <td>
                <button class="btn btn-light-danger mr-5" @click="deleteProduct(product.id)" title="Delete"><i class="fa fa-trash"></i></button>
                <button class="btn btn-light-success ml-5" @click="editProduct(product)" title="Edit"><i class="fa fa-edit"></i></button>
              </td>
            </tr>
            <tr v-if="!displayProducts.length">
              <td colspan="7" class="text-center">No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </section>
    <!-- Basic Tables end -->
  </div>
</template>
<style scoped>
.hover-cross{
  position: relative;
  width: 100px;
  background-color: #fff;
  border: 1px solid #ccc;
  transition: box-shadow 0.3s;
}
/* Style for the close icon */
.close-icon {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  margin-top: -60px;
  font-size: 48px;
  margin-left: 16px;
  color: #ffffff;
  display: none;
}
/* Show the close icon on hover */
.hover-cross:hover .close-icon {
  display: block;
}
.hover-cross:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: #00000054;
}
</style>

<script>

import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'
import ExcelTool from '../../components/general/bulk.upload'
import ProductService from '../../services/product.service'
import ValidationService from '../../services/validation.service'
import ProductModel from '../../models/ProductModel'
import FileService from '@/services/file.service'
import BrandService from '../../services/brand.service'
import BrandModelNew from '../../models/BrandModelNew'
import VehicleTypeService from '../../services/vehicleType.service'
import VehicleTypeModel from '../../models/VehicleTypeModel'

export default {
  name: 'Product',
  data () {
    return {
      ValidationSchema: ValidationService.createUpdateProduct,
      products: [],
      displayProducts: [],
      productBlueprints: [],
      ProductModel: new ProductModel(),
      openAddForm: false,
      feature_photos: [],
      thumbnails: [],
      brands: [],
      categories: [],
      subCategories: [],
      vehicleTypes: [],
      countries: [
        { id: 1, title: 'India', title_bangla: 'ভারত' },
        { id: 2, title: 'China', title_bangla: 'চীন' },
        { id: 3, title: 'Japan', title_bangla: 'জাপান' },
        { id: 4, title: 'South Korea', title_bangla: 'দক্ষিণ কোরিয়া' },
        { id: 5, title: 'United States of America', title_bangla: 'মার্কিন যুক্তরাষ্ট্র' },
        { id: 6, title: 'Germany', title_bangla: 'জার্মানি' },
        { id: 7, title: 'England', title_bangla: 'ইংল্যান্ড' }
      ],
      searchText: ''
    }
  },
  components: { Form, Field, ErrorMessage, ExcelTool },
  mounted () {
    this.getBrands()
    this.getVehicleTypesWithCategoriesAndSubCategories()
    this.getProducts()
  },
  watch: {
    searchText: function () {
      this.displayProducts = this.products.filter(item => item.name.toLowerCase().includes(this.searchText.toLowerCase()))
    }
  },
  computed: {
    headers () {
      if (this.products.length) {
        return _.filter(Object.keys(this.products[0]), function (p) {
          return p
        })
      }
      return []
    },
    childProperties () {
      const cproperties = []
      _.forEach(this.ProductModel.properties, function (p) {
        if (p.parent_propertyId === 0) {
          cproperties.push(...p.childs)
        }
      })
      return cproperties
    }
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    OnExcelProductsUpload (ExcelProducts) {
      const vm = this
      _.forEach(ExcelProducts, function (xclP) {
        xclP.id = 0
      })
      vm.createXclProduct(ExcelProducts)
    },

    getProducts () {
      this.products = []
      this.displayProducts = []
      ProductService.getProductsListWithBasicInfo().then(
        response => {
          if (response.data.status) {
            this.productBlueprints = _.map(response.data.objects.productBlueprints, function (p) {
              return new ProductModel().load(p)
            })
            this.products = _.map(response.data.objects.products, function (p) {
              return new ProductModel().load(p)
            })
            this.displayProducts = _.map(response.data.objects.products, function (p) {
              return new ProductModel().load(p)
            })
          }
        }
      )
    },
    createXclProduct (products) {
      ProductService.createProductInBulk(products).then(
        response => {
          if (response.data.status) {
            this.getProducts()
          }
        },
        () => {
        }
      )
    },
    updateXclProduct (product) {
      ProductService.updateProduct(product).then(
        response => {
          if (response.data.status) {
            this.getProducts()
          }
        }
      )
    },
    createOrUpdateProduct () {
      this.ProductModel.feature_photo_files = this.feature_photos
      this.ProductModel.thumbnail_files = this.thumbnails
      ProductService.createProduct(this.ProductModel).then(
        response => {
          if (response.data.status) {
            this.getProducts()
          }
        }
      )
    },
    removeThumbnailImage (img) {
      if (img === 'uploads') {
        this.thumbnails = []
      }
      let thumbnails = this.ProductModel.thumbnails.split('##**')
      thumbnails = thumbnails.filter(t => t && t !== img)
      this.ProductModel.thumbnails = thumbnails.join('##**')
    },
    removeFeaturePhotos (img) {
      if (img === 'uploads') {
        this.feature_photos = []
      }
      let thumbnails = this.ProductModel.feature_photos.split('##**')
      thumbnails = thumbnails.filter(t => t && t !== img)
      this.ProductModel.feature_photos = thumbnails.join('##**')
    },
    deleteProduct (ProductId) {
      const vm = this
      ProductService.deleteProduct(ProductId).then(
        response => {
          if (response.data.status) {
            vm.products = _.filter(vm.products, function (p) {
              return p.id !== ProductId
            })
            this.unloadDataTable()
            this.loadDataTable()
          }
        }
      )
    },
    onFeaturePhotosUpload (event) {
      const files = event.target.files ? event.target.files : null
      this.feature_photos = []
      if (!files.length) { return }
      FileService.prepareFileForUpload(files, this.ProductModel.id, 'product-feature-photos').then(
        response => {
          this.feature_photos = response
        },
        error => {
          console.log(error)
          alert('Error while trying to process uploaded files')
        }
      )
    },
    onThumbnailUpload (event) {
      const files = event.target.files ? event.target.files : null
      this.thumbnails = []
      if (!files.length) { return }
      FileService.prepareFileForUpload(files, this.ProductModel.id, 'thumbnails').then(
        response => {
          this.thumbnails = response
        },
        error => {
          console.log(error)
          alert('Error while trying to process uploaded files')
        }
      )
    },
    editProduct (ProductModel) {
      ProductService.getProductById(ProductModel.id).then(
        response => {
          if (response.data.status) {
            this.ProductModel.load(response.data.objects.product)
            this.populatePreselectedDropdownValues()
            this.openAddForm = true
          }
        }
      )
    },
    populatePreselectedDropdownValues () {
      const vehicleTypeProperty = this.ProductModel.fetchPropertyByTitle('Vehicle Type')
      this.autoSetBanglaPropertyValue(vehicleTypeProperty)
      const vehicleCategoryProperty = this.ProductModel.fetchPropertyByTitle('Vehicle Category')
      this.autoSetBanglaPropertyValue(vehicleCategoryProperty)
      const vehicleSubCategoryProperty = this.ProductModel.fetchPropertyByTitle('Vehicle Sub-Category')
      this.autoSetBanglaPropertyValue(vehicleSubCategoryProperty)
      const brandNameProperty = this.ProductModel.fetchPropertyByTitle('Brand Name')
      this.autoSetBanglaPropertyValue(brandNameProperty)
      const countryOfOriginProperty = this.ProductModel.fetchPropertyByTitle('Country of Origin')
      this.autoSetBanglaPropertyValue(countryOfOriginProperty)
    },
    updateProduct () {
      this.ProductModel.feature_photo_files = this.feature_photos
      this.ProductModel.thumbnail_files = this.thumbnails
      ProductService.updateProduct(this.ProductModel).then(
        response => {
          if (response.data.status) {
            this.getProducts()
            this.openAddForm = false
          }
        }
      )
    },
    getBrands () {
      BrandService.getBrands().then((response) => {
        if (response.data.status) {
          this.brands = _.map(response.data.objects.brands, function (b) {
            return new BrandModelNew().load(b)
          })
        }
      })
    },
    getVehicleTypesWithCategoriesAndSubCategories () {
      VehicleTypeService.getVehicleTypesWithCategoriesAndSubCategories().then((response) => {
        if (response.data.status) {
          this.vehicleTypes = _.map(response.data.objects.vehicleTypes, function (b) {
            return new VehicleTypeModel().load(b)
          })
        }
      })
    },
    autoSetBanglaPropertyValue (property) {
      if (['Brand Name', 'Vehicle Category', 'Vehicle Sub-Category', 'Vehicle Type', 'Country of Origin'].includes(property.title)) {
        if (!property.specification.value) {
          property.specification.value_bn = property.specification.value
        } else {
          let selectedEntity = null
          if (property.title === 'Brand Name') {
            selectedEntity = this.brands.find(x => x.title === property.specification.value)
          } else if (property.title === 'Vehicle Category') {
            this.subCategories = this.categories?.find(x => x.title === property.specification.value)?.sub_categories
            selectedEntity = this.categories?.find(x => x.title === property.specification.value)
          } else if (property.title === 'Vehicle Sub-Category') {
            selectedEntity = this.subCategories?.find(x => x.title === property.specification.value)
          } else if (property.title === 'Vehicle Type') {
            const selectedVehicleType = this.vehicleTypes?.find(x => x.title === property.specification.value)
            this.categories = selectedVehicleType?.categories
            selectedEntity = this.vehicleTypes?.find(x => x.title === property.specification.value)
          } else if (property.title === 'Country of Origin') {
            selectedEntity = this.countries?.find(x => x.title === property.specification.value)
          }
          if (selectedEntity) {
            property.specification.value_bn = selectedEntity.title_bangla
          }
        }
      }
    },
    reset () {
      this.ProductModel = new ProductModel()
    }
  }
}
</script>
