import UtilityService from '../services/utility.service'
export default class BrandModelNew {
  constructor (brandId) {
    this.id = 0
    this.title = ''
    this.title_bangla = ''
    this.thumbnail = ''
    this.brand_photo_file = []
    this.type_id = 0
    this.type_name = ''
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.title = data.title
    this.title_bangla = data.title_bangla
    this.thumbnail = data.thumbnail ?? ''
    this.brand_photo_file = data.brand_photo_file ?? []
    this.type_id = data.type_id
    this.type_name = data.type_name
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
